import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Line } from 'react-chartjs-2';
  import  { faker } from '@faker-js/faker';

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );
  


function Chart({ data }) {
    var data_obj = null;
    data_obj = JSON.parse(data)

    var total_vals = data_obj["data"]["total"]
    var http_vals = [];

    if("http" in data_obj["data"]){
        http_vals = data_obj["data"]["http"]
    }

    const data_start = new Date(Date.parse(data_obj["meta"]["dateRange/startTime"])) 

    const data_end = new Date( Date.parse(data_obj["meta"]["dateRange/endTime"]))
    const data_step = data_obj["meta"]["aggInterval"]
    var labels = [];
    
    for(var current_date = data_start; current_date <= data_end; current_date = new Date(current_date.getTime() + 60*60000)){
        labels.push(current_date.toTimeString().substring(0,18))
    }


    const options = {
        responsive: true,
        scales:{
            x: {
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: 20
                },
                title: {
                    display: true,
                    text: 'Time'
                }
            }
        },
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Chart.js Line Chart',
          },
        },
      };
      
      
      const data_g = {
        labels,
        datasets: [
          {
            label: 'Total',
            data: total_vals,
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
          },
          {
            label: 'HTTP',
            data: http_vals,
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
          },
        ],
      };

      if (!("http" in data_obj["data"])){
        data_g["datasets"].pop();
      }
    
    return (
		<Line options={options} data={data_g} />
		);
  }

  export default Chart;