

function RankList({ data }) {

    const ListData = (JSON.parse(data))['rankingEntries']

    return (
    <div>
        <table>
            <tr>
            <th>Rank</th>
            <th>Domain</th>
            <th>Category</th>
            <th>RankChange</th>
            </tr>
            {ListData.map((val, key) => {
            return (
                <tr key={key}>
                <td>{val.rank}</td>
                <td>{val.domain}</td>
                <td>{val.category}</td>
                <td>{val.rankChange}</td>
                </tr>
            )
            })}
        </table>
        </div>

    );
  }

  export default RankList;