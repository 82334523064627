import {useState} from "react";
import logo from './logo.svg';
import './App.css';
import Button from './components/button';
import RankList from './components/RankList';
import Chart from './components/Chart';

const BASE_URL = "https://dark-fog-4010.hannan6040.workers.dev/";

function App() {

  const [apiData, setApiData] = useState(null);

  const fetcher = (api) =>  async () => {
    const res = await fetch(api);
    const dataText = await res.json();
    console.log(dataText)
    setApiData(dataText);
  }
  
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <div className="buttons-row">
          <Button display_text={"Traffic Changes"} fetcher={fetcher(`${BASE_URL}traffic-change`)} />
          <Button display_text={"Domain Rankings"} fetcher={fetcher(`${BASE_URL}popular-domains`)} />
          <Button display_text={"Layer 3 Attacks"} fetcher={fetcher(`${BASE_URL}attack-layer3`)} />
        </div>
        <div className="data-placeholder">
        {
          apiData && (apiData?.rankingEntries) ?
          <RankList data={JSON.stringify(apiData)} />: 
           apiData ? <Chart data={JSON.stringify(apiData)} /> : null
        }
        </div>
      </header>
    </div>
  );
}

export default App;
